@media only screen and (min-width: 1200px){

.title
{
    text-align: center;
    color:#ff6000;
}

.title h1:first-child {
    margin-bottom: 0;
  }
  
  .title h1.sub {
    margin-top: 0;
  }


p.subtitle
{
  color:black;
  text-align: center;
  padding-left: 240px;
  text-align: center;
  justify-content: center;
}

div.form-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Styling the input fields */
  div.form-container form input {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #000;
    background: #fff;
    padding: 15px;
    margin: 15px 0;
    color: #000;
    font-size: 18px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
  }
  
  /*text area of form*/
  div.form-container form textarea {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #000;
    background: #fff;
    padding: 15px;
    margin: 15px 0;
    color: #000;
    font-size: 18px;
    border-radius: 6px;
  }

  div.form-container div.submission {
    padding-left: 300px;
  }
  
  /* submit button */
  div.form-container form button.btn {
    padding: 14px 60px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
    background-color: #ff6000;
    color: #fff;
    border: 0;
    border-radius: 6px;
  }
  
  /* Hover effect for the submit button */
  div.form-container form button.btn:hover {
    transform: scale(1.2);
  }
 
  /*submission confirmation message*/
  #msg
  {
    color: #ff6000;
    padding-top: 0px;
    margin-top: 40px;
    display: block;
  }

}

/*minimized tab*/
@media only screen and (max-width: 700px){

  .title
  {
      text-align: center;
      color:#ff6000;
  }
  
  .title h1:first-child {
      margin-bottom: 0;
    }
    
    .title h1.sub {
      margin-top: 0;
    }
  
  h1.sub
  {
      padding-left: 20px;
  
  }
  
  p.subtitle
  {
    padding-left: 40px;
    padding-right: 50px;
    color:#000;
    text-align: center;
    justify-content: center;
  
  
  }
  
  div.form-container {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    /* Styling the input fields */
    div.form-container form input {
      width: 100%;
      box-sizing: border-box;
      border: 2px solid #000;
      background: #fff;
      padding: 15px;
      margin: 15px 0;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    /*text area of form*/
    div.form-container form textarea {
      width: 100%;
      box-sizing: border-box;
      border: 2px solid #000;
      background: #fff;
      padding: 15px;
      margin: 15px 0;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
    }

    div.form-container div.submission {
      padding-left: 50px;
    }
    
    /* submit button */
    div.form-container form button.btn {
      padding: 14px 60px;
      font-size: 18px;
      margin-top: 20px;
      cursor: pointer;
      background-color: #ff6000;
      color: #fff;
      border: 0;
      border-radius: 6px;
    }
    
    /* Hover effect for the submit button */
    div.form-container form button.btn:hover {
      transform: scale(1.2);
    }
   
    /*submission confirmation message*/
    #msg
    {
      color: #ff6000;
      padding-top: 0px;
      margin-top: 40px;
      display: block;
  
    }
  
  }


@media only screen and (max-width: 480px){

  .title
  {
      text-align: center;
      color:#ff6000;
  }
  
  .title h1:first-child {
      margin-bottom: 0;
    }
    
    .title h1.sub {
      margin-top: 0;
    }

  h1.sub
  {
      padding-left: 20px;
  
  }
  
  p.subtitle
  {
    padding-left: 50px;
    padding-right: 50px;
    color:#000;
    text-align: center;
    justify-content: center;  
  }
  
  div.form-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    /* Styling the input fields */
    div.form-container form input {
      width: 100%;
      box-sizing: border-box;
      border: 2px solid #000;
      background: #fff;
      padding: 15px;
      margin: 15px 0;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
    }
    
    /*text area of form*/
    div.form-container form textarea {
      width: 100%;
      box-sizing: border-box;
      border: 2px solid #000;
      background: #fff;
      padding: 15px;
      margin: 15px 0;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
    }

    div.form-container div.submission {
      padding-left: 50px;
    }
    
    /* submit button */
    div.form-container form button.btn {
      padding: 14px 60px;
      font-size: 18px;
      margin-top: 20px;
      cursor: pointer;
      background-color: #ff6000;
      color: #fff;
      border: 0;
      border-radius: 6px;
      margin-left: auto;
      margin-right: auto;
    }
    
    /* Hover effect for the submit button */
    div.form-container form button.btn:hover {
      transform: scale(1.2);
    }
   
    /*submission confirmation message*/
    #msg
    {
      color: #ff6000;
      padding-top: 0px;
      margin-top: 40px;
      display: block;
  
    }
  
  }