
@media only screen and (min-width: 1200px) {


  .President {
    display: grid;
    place-items: center;
  }

  .Member {
    display: grid;
    place-items: center;
  }


  p.name {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 25px;
    margin-top: 10px;
  }

  .main_img {
    border-radius: 10%;
    max-width: 400px;
    height: 500px;
    border: 5px solid #474745; /* Add a blue border with a 5px width */
    box-shadow: 0px 0px 1000px rgba(219, 157, 126, 0.976); /* Add a slight shadow */
    align-content: center;
  }
  .link :hover{
    transition: transform 0.3s ease; /* Add a smoother transition for the transform property */
    transform: scale(1.2);
  }

  .haccs {
    font-size: 50px;
    font-family: 'Lato', sans-serif;
    color: #ff6600;
    text-align: center;
  }

  .Position
  {
    font-size: 50px;
    font-family: 'Lato', sans-serif;
    color: #ff6600;

  }

  .container
  {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2,1fr); 
  place-items: center;
  }
}

@media only screen and (max-width: 480px) {
  
  .President {
    display: grid;
    place-items: center;
  }

  .Member {
    display: grid;
    place-items: center;
  }

  body{
    max-width: 480px;
  }
  p.name {
    text-align: center;
    font-size:15px;
    margin-top: 10px;
  }

  .main_img {
    align-items: center;
    border-radius: 10%;
    max-width: 200px;
    height: 200px;
    border: 5px solid #474745; /* Add a blue border with a 5px width */
    box-shadow: 100px 100px 1000px rgba(219, 157, 126, 0.976); /* Add a slight shadow */
  }
  .link :hover{
    transition: transform 0.3s ease; /* Add a smoother transition for the transform property */
    transform: scale(1.2);
  }

  span.haccs {
    font-size:25px;
    font-family: 'Lato', sans-serif;
    color: #ff6600;
    text-align:center;
  }

  h1 {
    font-size: 20px;
    text-align: center;
  }

  .haccs
  {
    font-size: 25px;
    font-family: 'Lato', sans-serif;
    color: #ff6600;
    text-align: center;

  }


  .Position
  {
    font-family: 'Lato', sans-serif;
    color: #ff6600;
    place-items: center;
    display: grid;

  }

  .container{
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1,1fr); 
    place-items: center;
  }
}



/* Simple media query for phonesm */


/*Left over notes:

* Create meadia query for this page in specific

* Create media query for other pages

* merge to main and set up server on raspebrry  or github 

*/
