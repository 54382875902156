@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@font-face {
  font-family: 'Source Code Pro';
  src: local('Source Code Pro'), url(https://fonts.gstatic.com/s/sourcecodepro/v13/mrl8iJctOUT__6iNOj3yARsK4xQ.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Desktop view  */
@media only screen and (min-width: 1200px){
  .info {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 10px;
  }
  
  .icons {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top:55px;
   
  }
  
  .icons img{
    width: 150px;
    height: 150px;
    padding: 70px;
  }
  
  .content {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    right: 100px;
    top: 70px;
    
  }
  
  .vision,
  .mission {
    width: 900px;
    height: 200px;
    padding: 30px; 
  }
  .vision h1, .mission h1{
    text-align: left;
  }
  p{
    font-family: 'Source Code Pro', monospace;
    font-size: 14pt;
    text-align: justify;
    text-align-last: left ;
  }
  
  .app{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .carousel{
    width: 100%;
    height: 100vh;

  }
  

  

}
/* auto re-size for photos */
.app img{
  max-width: 100%;
  width:auto\9;
}



@media only screen and (max-width: 480px) {
  .carousel{
    width: 100%;
    height: 300px;
    align-content: center;
  }

  /* remove icons when on mobile */
  .icons {
    display: none;
    
  }
  .vision_text, .mission_text{
    display: flex;
    text-align: center;
    font-size: medium;
  
  }
 

}