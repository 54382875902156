.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
  text-align: center;
}
.alice-carousel__dots-item:not(.__custom)
{
    background-color: #fff;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #ff6600;
  }